@import "./partials/normalize";
@import "./partials/variables";
@import "./partials/iconfont";


button:focus{
  outline: 0;
}

.btn{
  &.focus, &:focus{
    box-shadow: none;
  }
}

html{
  height: 100%;
}

body{
  margin: 0;
  background: #fff;
  color: #555;
  font-family: Roboto, sans-serif;
  font-weight: 300;
  width: 100%;
  height: 100%;
  overflow-x:hidden;
}

img{
  max-width: 100%;
}

h1, h2, h3, h4, p{
  padding: 0;
  margin: 0;
}

h1, h2, h3, h4{
  font-family: "Roboto Slab", serif;
  font-weight: 400;
}

h1{
  font-size: 36px;
  line-height: 1.1;
}

h2{
  font-size: 28px;
  margin-bottom: 40px;
  line-height: 1.2;
}

h3{
  font-size: 24px;
  margin-bottom: 20px;
  line-height: 1.2;
}

// h2, h3, h4{
//   letter-spacing: 1px;
// }

h3, h4{
  color: #222;
}

.theme_light{
  color: #555;
  h2, h3, h4{
    color: #222;
  }
}

.theme_dark{
  color: #f8f9fa;
  h2, h3, h4{
    color: #fff
  }
}

li{
  padding: 0;
  margin: 0;
  display: block;
}

ul{
  padding: 0;
  margin: 0;
  display: block;
  margin-bottom: 25px;
}

a{
  transition: all .4s ease-in-out;
  color: #888;
  &:hover{
    color: #222;
    transition: all .4s ease-in-out;
    text-decoration: none;
  }
}

.container-fluid{
  padding-left: 20px;
  padding-right: 20px;
}

.text-dark{
  color: #222;
}

.text-bold{
  font-weight: 700;
}

.colortext{
  color: #702bce;
  font-weight: 700;
}

.text-intro{
  font-size: 18px;
  li{
    position: relative;
    padding-left: 1.5rem;
    margin-bottom: 0.8rem;
    &:before{
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #702bce;
      position: absolute;
      top: 12px;
      left: 0;
    }
  }
}

.no-wrap{
  white-space: nowrap;
}

.btn{
  border-radius: 3px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.2);
  padding: 12px 18px;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 16px;
  color: #fff;
  position: relative;
  top: 0;
  left: 0;
  transform: translate(0, 0);
  outline: 0;
  border: none;
  overflow: hidden;
  transition: 2s ease all;
  &:hover{
    transition: 2s ease all;
    color: #fff;
    &:before{
      right: -50%;
      transition: 1s ease all;
    }
  }
}

.btn2{
  border-radius: 3px;
  padding: 12px 18px;
  letter-spacing: 1px;
  font-weight: 600;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  color: #702bce;
  border: 1px solid #702bce;
  transition: 1s ease all;
  display: block;
  &:hover{
    transition: 1s ease all;
    color: #fff;
    background-color: #512f7f;
    border-color: #512f7f;
  }
}

.home-main__btn{
  background-color: #8273e9;
  margin-top: 25px;
  margin-bottom: 15px;
  &:hover{
    background-color: #6e5de6;
    transition: 2s ease all;
    transition: 2s ease all;
  }
}

.notmain__btn{
  background-color: #512f7f;
  color: #fff;
  &:hover{
    background-color: #9067c7;
    transition: 2s ease all;
  }
}

.section-padding{
  padding: 70px 0;
}

.header{
  background-color: #fff;
}

.header-inner{
  padding: 8px 15px;
  position: relative;
  font-family: "Roboto Slab", serif;
}

.header__logo{
  display: flex;
  height: 100%;
  align-items: center;
  width: 80%;
}

.header__menu{
  letter-spacing: 1px;
  font-size: 16px;
}

.nav-link{
  color: #555;
}

.navbar-toggler{
  width: 22px;
  height: 17px;
  position: relative;
  cursor: pointer;
  transition: all .4s ease-in-out;
  transform: rotate(0);
  float: right;
}

.menu-button_to-close span{
  &:nth-child(2){
    transform: rotate(45deg);
    top: 7px;
  }
  &:nth-child(1), &:nth-child(3), &:nth-child(4){
    opacity: 0;
  }
  &:nth-child(5){
    transform: rotate(-45deg);
    top: 7px;
  }
}

.menu-button_to-open span{
  &:nth-child(1){
    opacity: 0;
    top: -7px;
  }
  &:nth-child(2){
    top: 0;
  }
  &:nth-child(3), &:nth-child(4){
    top: 7px;
  }
  &:nth-child(5){
    top: 14px;
  }
}

.navbar-toggler span{
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: #1a110d;
  border-radius: 2px;
  opacity: 1;
  left: 0;
  transform: rotate(0);
  transition: all 0.4s ease-in-out;
}

.menu-button_to-open:hover span{
  &:nth-child(1){
    opacity: 1;
    transform: translateY(7px);
  }
  &:nth-child(2), &:nth-child(3), &:nth-child(4){
    transform: translateY(7px);
  }
  &:nth-child(5){
    transform: translateY(7px);
    opacity: 0;
  }
}

.block__contacts{
  display: flex;
  flex-direction: column;
  justify-content: center;
}




.block__contacts__phone{
  font-weight: 700;
  font-family: "Roboto Slab", serif;
  letter-spacing: 1px;
  position: relative;
  &:before{
    content: "\E003";
    font-family: icons;
    opacity: .6;
    position: absolute;
  }
}

.menu__phone{
  color: #702bce;
  font-size: 700;
  font-size:24px;
  font-weight: bold;
  letter-spacing: 1px;
  &:hover{
    color: #8b51db;
  }
}

.menu__worktime{
  color: #888;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  text-align: right;
}

.menu__contacts{
  padding-left: 25px;
}

.menu__phone:before{
  left: -25px;
}

.menu-wrapper{
  display: flex;
  flex-grow: 1;
  align-items: center;
  flex-direction: column;
  visibility: hidden;
  position: fixed;
  top: 73px;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: 320px;
  background-color: #fff;
  transition: .5s;
  height: 0;
  overflow: hidden;
}

.menu-list{
  margin-top: 40px;
  margin-bottom: 30px;
}

.menu_visible{
  visibility: visible;
  transition: .5s;
  height: 100%;
}

.home-main{
  padding-top: 82px;
  height: 100%;
  width: 100%;
  min-height: 630px;
  max-height: 900px;
}

.home-main__inner{
  height: 100%;
  width: 100%;
  background: #184b7a url(../img/home_main_sm.jpg) no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  color: #fff;
}

.home__box-title{
  line-height: 1.4;
  margin: auto;
  max-width: 320px;
}

.home__title{
  margin-bottom: 32px;
}

.home__description{
  max-width: 400px;
  font-size: 20px;
  margin-bottom: 25px;
}

.home__promo{
  font-size: 18px;
}

.strong-text{
  font-weight: 700;
  color: #a691fc;
  letter-spacing: 1px;
  padding-left: 3px;
}

.why{
  position: relative;
  &:after{
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: block;
    width: 50%;
    background-color: #f8f5fe;
    z-index: -1;
  }
}

.why__img{
  padding-top: 60px;
}

.box-service{
  max-width: 450px;
  margin: auto;
}

.services__list li{
  position: relative;
  padding-left: 1.5rem;
  margin-bottom: 0.8rem;
  &:before{
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #702bce;
    position: absolute;
    top: 7px;
    left: 0;
  }
}

.services img{
  display: block;
  margin: auto;
}

.box-service{
  padding: 30px 0;
  position: relative;
}

.services__request{
  text-align: center;
  color: #222;
}

.services__request__line1{
  font-size: 20px;
  margin-bottom: 10px;
}

.services__request__line2{
  font-size: 16px;
}

.services__btn{
  margin-top: 30px;
}

.methods{
  background-color: #2471a0;
}

.methods_heading{
  margin-bottom: 40px;
}

.box-methods{
  padding:0 15px;
  max-width:400px;
  margin: auto;
  &:before{
    display: block;
    margin-bottom: 10px;
  }
}

.box-methods__item1:before{
  content: url(../img/icon_meeting.png);
}

.box-methods__item2:before{
  content: url(../img/icon_online.png);
}

.box-methods__item3:before{
  content: url(../img/icon_email.png);
}

.box-methods__item1, .box-methods__item2{
  margin-bottom: 40px;
}

.box-methods__title{
  font-size:28px;
  margin-bottom:20px;
  font-family:'Roboto Slab', serif;
}

.box-methods__text{
  // max-width:300px;
  font-size:20px;
}

.about__bg{
  position: relative;
  &:before{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 40px;
    background-color: #f8f5fe;
  }
}

.about__title{
  margin-bottom: 0;
  margin-top: 60px;
}

.about__subtitle{
  font-size: 15px;
  color: #888;
  margin-bottom: 35px;
}

.about__quote{
  font-size: 18px;
  line-height: 1.3;
  padding-bottom: 50px;
  &:after, &:before{
    content: "\E004";
    font-family: icons;
    display: block;
    font-size: 15px;
    color: #d5d5d5;
    width: 15px;
    height: 15px;
  }
}

.about__top{
  margin-bottom: 50px;
  background-color: #f8f5fe;
}

.about__quote{
  &:before{
    transform: rotate(180deg);
    margin-bottom: 10px;
  }
  &:after{
    margin-top: 10px;
  }
}

.about__img{
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 100%;
}

.about p{
  margin-bottom: 20px;
}

.about__btn{
  margin-top: 0;
}

.vantages{
  background: url(../img/vantages_bg.jpg) no-repeat center center;
  background-size: cover;
}

.vantages__overlay{
  background-image: -webkit-linear-gradient(-21deg, #242659 0, #0199bf 99%);
  background-image: -ms-linear-gradient(-21deg, #242659 0, #0199bf 99%);
  opacity: 0.871;
}

.vantages__icon{
  margin-bottom: 30px;
}

.vantages__description{
  font-size: 20px;
  max-width: 600px;
  margin-bottom: 60px;
}


.box-vantages{
  font-size: 18px;
  // text-align: center;
}

.box-vantages__item1,
.box-vantages__item2{
  margin-bottom:50px;
}

.prices{
  position: relative;
  padding-top:70px; // how in .section-padding
}

.prices__img{
  margin-bottom: 50px;
}

.box-prices__item{
  justify-content: space-between;
  flex-direction: column;
  padding-bottom: 30px;
  position: relative;
  font-size: 18px;
  &::after{
    content: "";
    height: 5px;
    width: 100%;
    position: absolute;
    bottom: 15px;
    left: 0;
    background: -webkit-linear-gradient(250deg, #624dbc 30%, #49c1c7 70%);
    background: -o-linear-gradient(250deg, #624dbc 30%, #49c1c7 70%);
    background: linear-gradient(-160deg, #624dbc 30%, #49c1c7 70%);
  }
}

.box-prices__item__right {
    text-align: right;
}

.box-prices__value{
  font-size:26px;
  color:#49c1c7;
  font-weight: bold;
  letter-spacing:-1px;
}

.box-reviews{
  position: relative;
  padding: 30px;
  box-shadow: 0 1px 50px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
}

.box-reviews__review1, .box-reviews__review2, .box-reviews__review3{
  margin-bottom: 30px;
}

.box-reviews__top{
  margin-bottom: 20px;
}

.box-reviews__review3{
  background-color: #2471a0;
}

.reviews__image{
  padding-bottom: 20px;
}

.reviews h3{
  margin-bottom: 5px;
}

.reviews__from{
  opacity: .6;
  letter-spacing: 1px;
  font-size: 14px;
}

.reviews__heading-nophoto{
  margin-bottom: 30px;
  h3{
    color: #fff;
  }
}

.reviews__more{
  margin-top: 40px;
  font-size: 18px;
}

.reviews__more__item1{
  margin-bottom: 20px;
}

.reviews__more__link{
  text-align: center;
  color: #222;
  span{
    // padding-right: 50px;
    // position: relative;
    &:after{
      content: "→";
      // position: absolute;
      // font-family: icons;
      padding-left:20px;
      padding-right:20px;
      transition: all 0.4s ease-in-out;
    }
  }
  &:hover span:after{
    padding-left: 40px;
    padding-right: 0;
    transition: all 0.4s ease-in-out;
  }
}

.contacts{
  background: url(../img/contacts_bg.jpg) no-repeat;
  background-size: cover;
}

.contacts__text-intro{
  margin-bottom: 40px;
}

.form-control{
  &::placeholder{
    color: #555;
    opacity: 0.8;
    font-size: 16px;
    letter-spacing: 1px;
  }
  border-radius: 3px;
  background-color: #fff;
  border: 1px solid #bab8be;
  color: #222;
}

.contacts button.disabled{
  opacity: 1;
}

.form-control:focus{
  border-color: #6fd5e0;
  box-shadow: none;
  opacity: 1;
}

.rules{
  top: 0;
  left: 0;
}

.message{
  height: 200px;
}

.hidden{
  display: none !important;
  visibility: hidden !important;
}

#msgSubmit{
  margin-top: 30px;
  font-weight: 400;
}

.form-contacts{
  max-width: 460px;
  .text-danger{
    color: #fe1d1d !important;
  }
}

.form-group{
  span{
    display: none;
  }
  &.has-error span{
    display: block;
    color: #fe1d1d;
    font-weight: 400;
    letter-spacing: 1px;
    font-size: 14px;
  }
}

.message {
    min-height: 200px;
}

.form-check-label{
  color: #555;
}

.custom-control.custom-checkbox{
  color: #26a69a;
  .custom-control-input:checked ~ .custom-control-indicator{
    border-color: var(--color);
    transform: rotateZ(45deg) translate(1px, -5px);
    width: 10px;
    border-top: 0 solid #fff;
    border-left: 0 solid #fff;
  }
  .custom-control-indicator{
    display: inline-block;
    position: absolute;
    top: 4px;
    left: 0;
    width: 16px;
    height: 16px;
    border: 2px solid #aaa;
    transition: .3s;
  }
}

.contacts__contacts{
  padding-left: 30px;
  margin-top: 30px;
}

.contacts__phone{
  color: #702bce;
  font-size: 24px;
  &:before{
    left: -30px;
  }
  &:hover{
    color: #8b51db;
  }
}

.contacts__technologies{
  opacity: 0.6;
}

.contacts__worktime{
  font-size: 15px;
}

.submit-wrap{
  margin-top:30px;
}

.form-submit{
  width:100%;
}

.form-result{
  // color: green;
  // padding: 10px 30px 10px 20px;
  margin-top: 20px;
  font-size: 18px;
  position: relative;
  display: none;
}

.result_error {
    color: red;
    background-color: #fbdddd;
    padding: 10px 30px 10px 20px;
    border: 1px solid red;
}
.result_success {
    color: green;
    background-color: #e7f0e7;
    padding: 10px 30px 10px 20px;
    border: 1px solid green;
  }

.contacts__close-btn{
  position: absolute;
  top: 0;
  right: 10px;
  font-size: 30px;
  line-height: 1;
  &:hover{
    cursor: pointer;
  }
}

.ajax-loader{
  display: none;
}

.g-recaptcha{
  transform: scale(.9, .9);
  transform-origin: left top;
  margin-bottom:20px;
}

.footer__top{
  background-color: #2471a0;
  padding:60px 0;
}

.footer__content{
  display: flex;
  flex-direction: column;
}

.footer__col:not(:last-child){
  margin-bottom: 30px;  
}

.footer__bottom{
  background-color: #155780;
  padding-top: 18px;
  padding-bottom: 18px;
  text-align: center;
}

.footer__contacts{
  padding-left: 30px;
  margin-top: 40px;
}

.footer__technologies{
  opacity: 0.6;
}

.footer__logo{
  margin-bottom: 34px;
  & img{
    display: block;
  }
}

.footer__service1{
  margin-bottom: 5px;
  opacity: 0.7;
}

.footer__service2{
  opacity: 0.7;
}

.footer__phone{
  color: #fff;
  font-size: 24px;
  opacity: 1;
  &:hover{
    color: #fff;
    opacity: 0.6;
  }
}

.footer__worktime{
  font-size: 14px;
  opacity: 0.8;
}

.footer__social a{
  color: #fff;
  opacity: 0.4;
  &:hover{
    color: #fff;
    opacity: 0.8;
  }
}

// .footer__social_vk{
//   padding-right: 10px;
// }

.footer__bottom{
  font-size: 14px;
  color: rgba(255, 255, 255, 0.4);
}

.footer__phone:before{
  left: -30px;
}

.footer__social{
  width:150px;
  display: flex;
  justify-content: space-between;
  font-size: 45px;
  // margin-top: 40px;
  color: #fff;
  line-height:1;
}

.footer__policy-link{
  color: #fff;
  opacity: 0.4;
  &:hover{
    color: #fff;
    opacity: 1;
  }
}

.navbar-nav .nav-link{
  position: relative;
  &:hover{
    color: #702bce;
  }
  &:after{
    content: '';
    position: absolute;
    width: 0;
    height: 1px;
    background: #702bce;
    transition: .5s;
    opacity: 0;
  }
  &:before{
    content: '';
    position: absolute;
    width: 0;
    height: 1px;
    background: #702bce;
    transition: .5s;
    opacity: 0;
    top: 0;
    left: 0;
  }
  &:after{
    bottom: 0;
    right: 0;
  }
  &:hover{
    &:after, &:before{
      opacity: 1;
      width: 100%;
      transition: 0.5s;
    }
  }
}

.callback-popup__overlay{
  z-index: 1040;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #000;
  opacity: .8;
  filter: alpha(opacity = 80);
  cursor: pointer;
  display: none;
}

.callback-popup__content{
  width: 100%;
  max-width: 400px;
  color: #fff;
  background: #fff;
  position: fixed;
  display: none;
  opacity: 0;
  padding: 30px 40px 40px 40px;
  z-index: 1041;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.callback-popup__btn-close{
  width: 21px;
  height: 21px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  display: block;
  line-height: 21px;
  font-size: 50px;
  color: #222;
  z-index: 10;
}

.callback .form-control{
  border: 1px solid #ccc;
  box-shadow: none;
  &:focus{
    box-shadow: none;
  }
}

.default-checkbox_hidden{
  opacity: 0 !important;
}

.form-callback__btn{
  width: 100%;
}

.form-note{
  color: #222;
}



@media (min-width: 576px){
  h1{
    font-size: 60px;
  }
  h2{
    font-size: 40px;
    margin-bottom: 80px;
  }
  h3{
    font-size: 28px;
    margin-bottom: 40px;
  }
  .text-intro{
    font-size: 22px;
  }
  .btn{
    padding: 14px 40px;
    letter-spacing: 2px;
    font-size: 19px;
  }
  .section-padding{
    padding: 120px 0;
  }
  .menu__logo{
    width: auto;
  }
  .home-main{
    min-height: 730px;
  }
  .strong-text{
    font-size: 24px;
    position: relative;
    &::after{
      background: url(../img/arrow-attention.svg) no-repeat;
      position: absolute;
      content: "";
      display: block;
      height: 40px;
      width: 40px;
      top: -5px;
      right: -50px;
      background-size: contain;
    }
  }
  .home__box-title{
    max-width: 400px;
  }
  .home__description{
    font-size: 24px;
  }
  .box-service{
    //padding: 100px 100px 100px 200px;
    padding: 50px 0 60px 120px;
    &:before{
      position: absolute;
      //top: 100px;
      //left: 40px;
      top: 50px;
      left: 0;
      line-height: 1;
      font-size: 80px;
      //font-size: 110px;
      font-family: "Roboto Slab", serif;
      font-weight: 700;
      display: block;
      transform: rotate(-90deg);
      background: -webkit-linear-gradient(250deg, #49c1c7 30%, #624dbc 70%);
      background: -o-linear-gradient(250deg, #49c1c7 30%, #624dbc 70%);
      background: linear-gradient(-160deg, #49c1c7 30%, #624dbc 70%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .box-service__service1:before{
    content: "01";
  }
  .box-service__service2:before{
    content: "02";
  }
  .box-service__service3:before{
    content: "03";
  }
  .box-service__service4:before{
    content: "04";
  }
  .box-service__service5:before{
    content: "05";
  }
  .box-service__service6:before{
    content: "06";
  }
  .box-service__service7:before{
    content: "07";
  }
  .services__request__line1{
    font-size: 30px;
    max-width:800px;
    margin:auto;
    text-align: left;
  }
  .services__request__line2{
    font-size: 24px;
  }
  .about__title{
    margin-bottom: 0;
    margin-top: 90px;
  }
  .about__quote{
    font-size: 21px;
  }
  .vantages__description{
    font-size: 28px;
  }
  .box-vantages{
    font-size: 20px;
  }
  .prices{
    padding-top:120px; //how in .section-padding
  }
  .prices__img{
    margin-bottom: 90px;
  }
  .box-prices__item{
    flex-direction: row;
    align-items: center;
  }
  .box-reviews:before{
    content: "\E005";
    font-family: icons;
    position: absolute;
    right: -40px;
    top: -150px;
    font-size: 300px;
    opacity: 0.05;
  }
  .contacts__contacts{
    padding-left: 40px;
  }
  .contacts__phone{
    font-size: 30px;
    &:before{
      left: -40px;
    }
  }
  .g-recaptcha{
    transform: none;
  }
}

@media (min-width: 768px){
  .container-fluid{
    padding-left: 40px;
    padding-right: 40px;
  }
  .home-main{
    min-height: 750px;
  }
  .methods_heading{
    margin-bottom: 80px;
  }
  .about__top{
    background-color: transparent;
  }
  .box-reviews{
    padding: 80px;
  }
  .footer__top{
    padding:80px 0;
    text-align: left;
  }
  .footer__col{
    margin-bottom:0;
  }
  .footer__content{
    flex-direction: row;
    justify-content: space-between;
    align-items:flex-start;
  }
  .footer__contacts{
    margin-top: 0;
  }
  // .footer__social{
  //   text-align: center;
  // }
}

@media (min-width: 992px){
  .header__logo{
    width: auto;
  }
  .menu__logo{
    width: 85%;
  }
  .menu-wrapper{
    flex-direction: row;
    height: auto;
    visibility: visible;
    position: static;
    opacity: 1;
  }
  .menu-list{
    margin: auto;
  }
  .home-main{
    max-height: 900px;
  }
  .home-main__inner{
    background: #184b7a url(../img/home_main.jpg) no-repeat;
    background-size: cover;
    background-position: 30%;
  }
  .home__box-title{
    margin: 0;
  }
  .why::after{
    width: 33%;
  }
  .why__img{
    padding-top: 0;
  }
  .services img{
    display: inline-block;
  }
  .box-service{
    padding-top: 100px;
    padding-bottom:100px;
    &:before{
      top: 100px;
    }
  }
  .services__request{
    padding-top: 80px;
  }
  .methods_heading, .box-methods__item1, .box-vantages{
    margin-bottom: 0;
  }
  .box-methods__text{
    margin:0;
  }
  .vantages__h2{
    text-align:left;
  }
  .prices{
    padding-bottom:120px; //how in .section-padding
  }
  .prices:before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    display: block;
    width: 35%;
    background-color: #2471a0;
    z-index: -1;
  }
  .prices__img{
    margin-bottom: 0;
  }
  .box-prices{
    padding-left: 40px;
    padding-right: 40px;
  }
  .contacts__contacts{
    margin-top: 0;
  }
  .footer__social{
    text-align: right;
    margin-top: 0;
  }
}

@media (min-width: 1200px){
  .container-fluid{
    padding-left: 45px;
    padding-right: 45px;
  }
  .header-inner{
    padding-left: 40px;
    padding-right: 40px;
  }
  .menu__logo{
    width: auto;
  }
  .block__contacts{
    height: 100%;
  }
  .home-main__inner{
    background-position: center center;
  }
  .home__box-title{
    max-width: 550px;
  }
  .home__description{
    max-width: 100%;
  }
  .strong-text::after{
    height: 60px;
    width: 60px;
    top: -15px;
    right: -70px;
  }
  .prices:before{
    background-color: #f8f5fe;
  }

  .box-prices{
    padding-left:80px;
  }
  .box-prices__item{
    flex-direction: row;
  }
  .box-reviews{
    padding: 80px;
  }
}

@media (min-width: 1400px){
  .container-fluid{
    max-width: 1400px;
    margin: auto;
  }
}